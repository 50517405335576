import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import scheduleApolloClient from '@/lib/appsync/schedule';
import postNewUserScheduleRule from '@/graphql/userSettings/mutations/postNewUserScheduleRule.graphql';
import deleteScheduledJob from '@/graphql/schedule/mutations/deleteScheduledJob.graphql';
import deleteGroup from '@/graphql/schedule/mutations/deleteGroup.graphql';



import postNewScheduledJob from '@/graphql/userSettings/mutations/postNewScheduledJob.graphql';
import putScheduledJob from '@/graphql/schedule/mutations/putScheduledJob.graphql';
import postGanttMutation from '@/graphql/schedule/mutations/postGantt.graphql';
import postGroupMutation from '@/graphql/schedule/mutations/postGroup.graphql';

import postClusterMutation from '@/graphql/schedule/mutations/postCluster.graphql';

import putTasksParams from '@/graphql/schedule/mutations/putTasksParams.graphql';

import updateScheduledJobDateRanges from '@/graphql/schedule/mutations/updateScheduledJobDateRanges.graphql';


import completePrePostTasqJob from '@/graphql/schedule/mutations/completePrePostTasqJob.graphql';
import deleteRig from '@/graphql/schedule/mutations/deleteRig.graphql';
import deleteRule from '@/graphql/schedule/mutations/deleteRule.graphql';

import postNewPrePostTasqTemplate from '@/graphql/userSettings/mutations/postNewPrePostTasqTemplate.graphql';
import postNewRig from '@/graphql/userSettings/mutations/postNewRig.graphql';
import putScoot from '@/graphql/schedule/mutations/putScoot.graphql';



import getScheduleRulesResponse from '@/graphql/userSettings/queries/getScheduleRulesResponse.graphql';
import getScheduledJobsResponse from '@/graphql/userSettings/queries/getScheduledJobsResponse.graphql';
import getComponentsResponse from '@/graphql/schedule/queries/getComponentsResponse.graphql';
import getVendorsResponse from '@/graphql/schedule/queries/getVendorsResponse.graphql';
import getEnabledRoutes from '@/graphql/schedule/queries/getEnabledRoutes.graphql';

import getGanttChartIDs from '@/graphql/schedule/queries/getGanttChartIDsResponse.graphql';

import getGanttResponse from '@/graphql/schedule/queries/getGanttResponse.graphql';



import getPrePostTasqTemplateResponse from '@/graphql/userSettings/queries/getPrePostTasqTemplateResponse.graphql';
import PrePostTasqTemplate from '@/interfaces/schedule/PrePostTasqTemplate';
import { mapPrePostTasqTemplate } from '@/utils/scheduleJobs';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'scheduleModule',
  store,
})
class ScheduleModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  scheduleRules: any[] = []
  scheduleJobs: any[] = []
  scheduleComponents: any[] = []
  scheduledRigComponents: any[] = []

  enabledRoutes: any[] = []

  ganttChartIDs: any[] = []

  preTasqTemplateList: PrePostTasqTemplate[] = []
  postTasqTemplateList: PrePostTasqTemplate[] = []

  scheduledRowComponents: any[] = []


  @Mutation
  resetRoutes(): void {
    this.enabledRoutes = [];
  }


  @Mutation
  resetScheduleRules(): void {
    this.scheduleRules = [];
  }

  @Mutation
  resetScheduleJobs(): void {
    this.scheduleJobs = [];
  }

  @Mutation
  resetScheduleComponents(): void {
    this.scheduleComponents = [];
  }

  @Mutation
  resetScheduledRigComponents(): void {
    this.scheduledRigComponents = [];
  }





  @Mutation
  setGanttJobs(get_chart_ids): void {
	this.ganttChartIDs = get_chart_ids
  }



  @Mutation
  setGanttJob(get_chart_id): void {
	this.ganttChartIDs.push(JSON.parse(get_chart_id))
  }




  @Mutation
  addRoute(route): void {
    this.enabledRoutes.push(route);
  }

  @Mutation
  setScheduleJobs(schedule_jobs): void {
    this.scheduleJobs = schedule_jobs;
  }

  @Mutation
  setScheduleRules(schedule_rules): void {
    this.scheduleRules = schedule_rules;
  }

  @Mutation
  setScheduleComponents(schedule_components): void {
    this.scheduleComponents = schedule_components;
  }

  @Mutation
  setScheduledRigComponents(schedule_components): void {
    this.scheduledRigComponents = schedule_components;
  }

  @Mutation
  addScheduleRule(rule): void {
    this.scheduleRules.push(rule);
  }

  @Mutation
  addPreTasqTemplate(pre_tasq): void {
    this.preTasqTemplateList.push(pre_tasq);
  }

  @Mutation
  addPostTasqTemplate(post_tasq): void {
    this.postTasqTemplateList.push(post_tasq);
  }

  @Mutation
  resetPrePostTasqTemplate(): void {
    this.postTasqTemplateList = [];
	this.preTasqTemplateList = [];
  }


  @Mutation
  addScheduledJob(job): void {
    this.scheduleJobs.push(job);
  }

  @Mutation
  addScheduledRig(component): void {
    this.scheduledRigComponents.push(component);
  }






  @Action
  async putTasksParams({
	GanttChartID,
	TaskID,
	ModifyObject,
	Value
  }) {

	  try {
		  const {
		  data: {
			put_tasks_params: result,
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: putTasksParams,
		  variables: {
			  input: {
				GanttChartID: GanttChartID,
				TaskID: TaskID,
				Object: ModifyObject,
				Value: Value
			  },
		  },
		  });

		  let well_test_data = JSON.parse(result.result)
   
		  let formatted_response = await this.setScheduledComponents(well_test_data)
		  this.resetScheduledRowComponents()
		  for (let x = 0; x < formatted_response.length; x++) {
			  const parsed_results = formatted_response[x];
			  this.addScheduledRow(parsed_results);
		  }

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }

//   ClusterParams: AWSJSON!
//   DefaultDurationHours: Int!
//   GanttChartID: String!
//   GroupMapPath: String!
//   LastDateCompleted: [String!]
//   NodeIDs: [String!]

  @Action
  async postCluster({
	GanttChartID,
	GroupMapPath,
	NodeIDs,
	DefaultDurationHours,
	LastDateCompleted,
	ClusterParams
  }) {

	  try {
		  const {
		  data: {
			post_cluster: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postClusterMutation,
		  variables: {
			  input: {
				GanttChartID: GanttChartID,
				GroupMapPath: GroupMapPath,
				DefaultDurationHours: DefaultDurationHours,
				NodeIDs: NodeIDs,
				LastDateCompleted: LastDateCompleted,
				ClusterParams: ClusterParams
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async postGroup({
  gantt_chart_id,
  group_structure
  }) {

	  try {
		  const {
		  data: {
			post_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postGroupMutation,
		  variables: {
			  input: {
				GanttChartID: gantt_chart_id,
				GroupStructure: group_structure
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async postGantt({
  chart_name
  }) {

	  try {
		  const {
		  data: {
			post_gantt: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postGanttMutation,
		  variables: {
			  input: {
				GanttChartName: chart_name,
				Operator: getConfigEnv('OPERATOR_LOWERCASED')
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Mutation
  resetScheduledRowComponents(): void {
    this.scheduledRowComponents = [];
  }

  @Mutation
  addScheduledRow(component): void {
    this.scheduledRowComponents.push(component);
  }






  @Action
  setScheduledComponents(well_test_data): any {

	
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
	var componentVueIDCount = 0
	var formatted_response: any[] = []
	for (const [header_key, header_value] of Object.entries(well_test_data)) {
		var header_rows: any[] = []
		var header_dict = {rows:header_rows, title_row: {selected: true, selection_id: header_key, selection_name: header_key}}
		var testers_dict = header_value

		// @ts-ignore
		for (const [tester_key, tester_value] of Object.entries(testers_dict)) {
			var header_row_dict_components_array: any[] = []
			var header_row_dict = {components:header_row_dict_components_array, title_row:{selected: true, selection_id:tester_key, selection_name: tester_key}}

			// @ts-ignore
			for (var x = 0; x < tester_value.length; x++) {
				// @ts-ignore
				var start_date = new Date(tester_value[x].TargetDate)
				// @ts-ignore
				var end_date = new Date(tester_value[x].TargetDate)

				// @ts-ignore
				end_date.setDate(end_date.getDate() + (Math.ceil(tester_value[x].CurrentDurationHours / 24)));

				var start_date_string = start_date.getFullYear() + "-" + pad(start_date.getMonth() + 1, 2, '0') + "-" + pad(start_date.getDate(), 2, '0') + "T" + pad(start_date.getHours(), 2, '0') + ":" + pad(start_date.getMinutes(), 2, '0')+ ":" + pad(start_date.getSeconds(), 2, '0')
				var end_date_string = end_date.getFullYear() + "-" + pad(end_date.getMonth() + 1, 2, '0') + "-" + pad(end_date.getDate(), 2, '0') + "T" + pad(end_date.getHours(), 2, '0') + ":" + pad(end_date.getMinutes(), 2, '0')+ ":" + pad(end_date.getSeconds(), 2, '0')
				componentVueIDCount += 1
				var component_dict = {
					// @ts-ignore
					assignee: tester_value[x].ClusterID,
					// @ts-ignore
					component_vue_id: tester_value[x].TaskID + '' + componentVueIDCount,
					// @ts-ignore
					component_id: tester_value[x].TaskID,
					// @ts-ignore
					last_date_completed: tester_value[x].LastDateCompleted,
					// @ts-ignore
					flag_scoot: tester_value[x].FlagScoot,
					start_date: start_date_string,
					end_date: end_date_string,
					pre_tasq_checklist: [],
					post_tasq_checklist: [],
					title_row: {selected: true, selection_id:tester_key, selection_name: tester_key},
					// @ts-ignore
					well: tester_value[x].NodeID
				}
				header_row_dict_components_array.push(component_dict)


			}
			header_row_dict.components = header_row_dict_components_array
			header_dict.rows.push(header_row_dict)
		}
		formatted_response.push(header_dict)
	}

	return formatted_response



  }








  @Action
  async getGantt({
	  gantt_chart_id
  }): Promise<void> {

  const {
      data: {
        get_gantt: getGanttResults,
      },
    } = await scheduleApolloClient.query({
      query: getGanttResponse,
      variables: {
        input: {
			GanttChartID: gantt_chart_id
        },
      },
    });
	console.log("getGanttResults")
	console.log(getGanttResults)
	console.log(JSON.parse(getGanttResults.results))

	var well_test_data = JSON.parse(getGanttResults.results)
   
	let formatted_response = await this.setScheduledComponents(well_test_data)
	this.resetScheduledRowComponents()
	for (let x = 0; x < formatted_response.length; x++) {
		const parsed_results = formatted_response[x];
		this.addScheduledRow(parsed_results);
	}


  }






  @Action
  async getGanttChartIDs(): Promise<void> {

  const {
      data: {
        get_gantt_chart_ids: getGanttChartIDsResults,
      },
    } = await scheduleApolloClient.query({
      query: getGanttChartIDs,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });
	console.log("getGanttChartIDsResults")
	console.log(getGanttChartIDsResults)

	for (var x = 0; x < getGanttChartIDsResults.results.length; x++) {
		this.setGanttJob(getGanttChartIDsResults.results[x])
	}

	// this.resetRoutes();
    // for (let x = 0; x < enabledRoutesResults.results.length; x++) {
    //   const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
    //   this.addRoute(parsed_results);
    // }
  }




  @Action
  async getEnabledRoutes(): Promise<void> {

    const {
      data: {
        get_enabled_routes: enabledRoutesResults,
      },
    } = await scheduleApolloClient.query({
      query: getEnabledRoutes,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });
	this.resetRoutes();
    for (let x = 0; x < enabledRoutesResults.results.length; x++) {
      const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
      this.addRoute(parsed_results);
    }
  }





//   delete_group(input: {GanttChartID: "", GroupStructure: ""}) {
//     error
//     result
//     success
//   }

  @Action
  async deleteWellTestGroup({
  gantt_chart_id,
  group_structure
  }) {

	  try {
		  const {
		  data: {
			delete_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteGroup,
		  variables: {
			  input: {
				GanttChartID: gantt_chart_id,
				GroupStructure: group_structure
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }





  @Action
  async deleteRule({
  rule_id
  }) {

	  try {
		  const {
		  data: {
			delete_rule: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRule,
		  variables: {
			  input: {
				  RuleID: rule_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }









  @Action
  async scootFlagSelected({
  task_id,
  gantt_chart_id,
  cluster_id
  }) {

	  try {
		  const {
		  data: {
			put_scoot: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: putScoot,
		  variables: {
			  input: {
				  TaskID: task_id,
				  GanttChartID: gantt_chart_id,
				  ClusterID: cluster_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }












  @Action
  async deleteRig({
  rig_id
  }) {

	  try {
		  const {
		  data: {
			delete_rig: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRig,
		  variables: {
			  input: {
				  RigID: rig_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }







  @Action
  async completePrePostTasqJob({
  tasq_id,
  tasq_type,
  prediction_id
  }) {

	  try {
		  const {
		  data: {
			complete_pre_post_tasq_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: completePrePostTasqJob,
		  variables: {
			  input: {
				  TasqID: tasq_id,
				  TasqType: tasq_type,
				  PredictionID: prediction_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }















@Action
  async getScheduleRulesAndComponents({
    from_date,
    to_date,
  }): Promise<void> {
    this.resetScheduleRules();
    this.resetScheduleComponents();
    const {
      data: {
        get_schedule_rules: scheduleRules,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleRulesResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          from_date,
          to_date,
        },
      },
    });
    for (let x = 0; x < scheduleRules.results.length; x++) {
      const parsed_results = JSON.parse(scheduleRules.results[x]);
      this.addScheduleRule(parsed_results);
    }

    this.setScheduleComponents(JSON.parse(scheduleRules.structured_components));
  }




  @Action
  async getScheduledJobs({
	start_date,
    end_date,
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
        get_scheduled_jobs: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduledJobsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRig(parsed_results);
	}


  }


  @Action
  async getPrePostTasqTemplates(): Promise<void> {
	this.resetPrePostTasqTemplate();
    const {
      data: {
        get_pre_post_tasq_templates: prePostTasqTemplates,
      },
    } = await scheduleApolloClient.query({
      query: getPrePostTasqTemplateResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });

	const templatePrePostTasqs = (prePostTasqTemplates.results).map(
		(t: PrePostTasqTemplate) => mapPrePostTasqTemplate(t),
	  );
	this.resetPrePostTasqTemplate();
	for (var x = 0; x < templatePrePostTasqs.length; x++) {
		if (templatePrePostTasqs[x].tasqType == "PreTasq") {
			this.addPreTasqTemplate(templatePrePostTasqs[x])
		} else {
			this.addPostTasqTemplate(templatePrePostTasqs[x])
		}
	}
  }





  @Action
  async getComponents(): Promise<void> {
    const {
      data: {
        get_components: getComponentsResults,
      },
    } = await scheduleApolloClient.query({
      query: getComponentsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }


  @Action
  async getVendors(): Promise<void> {
    const {
      data: {
        get_vendors: getVendorsResults,
      },
    } = await scheduleApolloClient.query({
      query: getVendorsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }









  @Action
  async deleteScheduledJob({
  workflow_task_id,
  }) {
	  try {
		  const {
		  data: {
			delete_scheduled_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteScheduledJob,
		  variables: {
			  input: {
				WorkflowTaskID: workflow_task_id,
				Operator: getConfigEnv('OPERATOR_LOWERCASED')
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }

  }








@Action
	async postNewUserScheduleRule({
	username,
	rule_conditions_dict,
	}) {
		try {
			const {
			data: {
				post_new_user_schedule_rule: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewUserScheduleRule,
			variables: {
				input: {
				Username: username,
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				RuleConditionsDict: rule_conditions_dict,
				},
			},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



@Action
	async postNewScheduledJob({
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	role,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				post_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					JobOperator: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					Role: role,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username,
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async putScheduledJob({
	workflow_task_id,
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	rig_id,
	rig_name,
	selected_end_date,
	selected_start_date,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				put_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: putScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					SelectedOperatorEmail: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username,
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist,
					WorkflowTaskID: workflow_task_id
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}






	@Action
	async updateScheduledJobDateRanges({
	prediction_id,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name
	}) {

		try {
			const {
			data: {
				update_scheduled_job_date_ranges: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateScheduledJobDateRanges,
			variables: {
				input: {
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					PredictionID: prediction_id,
					RigID: rig_id,
					RigName: rig_name
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}





	@Action
	async postNewPrePostTasqTemplate({
		TasqType,
		TasqTitle,
		TasqSummary
	}) {

		try {
			const {
			data: {
				post_new_pre_post_tasq_template: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewPrePostTasqTemplate,
			variables: {
				input: {
					TasqType: TasqType,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					TasqTitle: TasqTitle,
					TasqSummary: TasqSummary
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




	@Action
	async postNewRig({
		RigName,
		RigID
	}) {

		try {
			const {
			data: {
				post_new_rig: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewRig,
			variables: {
				input: {
					RigName: RigName,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					RigID: RigID
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




}










export default getModule(ScheduleModule);
